<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="displayModal= true"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-25 d-none d-sm-inline"
      />
      <span>{{ $t('location.print') }}</span>
    </b-button>
    <b-modal
      id="print-location-modal"
      ref="print-location-modal"
      v-model="displayModal"
      centered
      hide-footer
      size="xl"
    >
      <div
        ref="printForm"
      >
        <div class="d-flex justify-content-between align-items-start flex-sm-row flex-column">
          <div class="width-400 mb-1 mb-sm-0">
            <b-avatar
              v-for="locale in localesList"
              :key="locale.locale"
              :variant="`${$i18n.locale===locale.label?'warning':'primary'}`"
              class="lang"
            >
              <span class="text-uppercase">{{ locale.name }}</span>
            </b-avatar>
          </div>
          <div class="d-flex justify-content-end align-items-center flex-sm-row flex-column mb-2">
            <p class="brand-text text-right">
              <span><b>{{ appName }}</b></span> - {{ $t('about_the_project') }}  <br><em>{{ appUrl }}</em>
            </p>
            <p>
              <b-img
                :src="appLogoImage"
                alt="logo"
                height="64px"
                class="ml-2"
              />
            </p>
          </div>
        </div>

        <b-card
          id="locationRequest"
          no-body
        >
          <b-card-header>
            <b-card-title>
              <div class="d-flex">
                <feather-icon
                  icon="PhoneCallIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  {{ $t('Service Request') }}
                </h4>
              </div>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-card-text class="mt-2 mb-4">
              <em>

                <span
                  v-for="(purpose,index) in getServicePurposes"
                  :key="index"
                >
                  {{ getPurposeTitle(purpose) }},
                </span>
                {{ $t('point.type.other') }}...

              </em>
            </b-card-text>
            <location-add-request
              :location="location"
            />
          </b-card-body>

        </b-card>
        <b-card
          id="openMapModal"
          no-body
        >
          <b-card-header>
            <b-card-title>
              <div class="d-flex">
                <feather-icon
                  icon="MapPinIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  {{ $t('Address') }}
                </h4>
              </div>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <h5>{{ getFormattedAddress(location.address) }}</h5>
          </b-card-body>
        </b-card>

      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="generatePDF(location)"
      >
        <span
          v-if="loading"
          class="align-middle"
        >
          <b-spinner
            type="grow"
            small
          />
          {{ $t('Loading...') }}
        </span>
        <span
          v-else
          class="align-middle"
        >
          <feather-icon
            icon="PrinterIcon"
            class="mr-50"
          /> {{ $t('location.print') }}</span>

      </b-button>
    </b-modal>
  </div>

</template>

<script>
import {
  BButton, BModal, BSpinner, BCard, BCardBody, BCardHeader, BCardTitle, BImg, BCardText, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useLocationUi } from '@/views/apps/location/useLocation'
// import { jsPDF } from 'jspdf'
import html2pdf from 'html2pdf.js'
import { $themeConfig } from '@themeConfig'
import LocationAddRequest from '@/views/apps/location/details/LocationAddRequest.vue'
import { getFormattedAddress } from '@/mixins/functions'
// import html2canvas from 'html2canvas'
import { ref } from '@vue/composition-api'
import { locales, serviceOptions } from '@/mixins/options'

export default {
  components: {
    BButton,
    BModal,
    BSpinner,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BImg,
    BCardText,
    BAvatar,
    LocationAddRequest,
  },
  directives: {
    Ripple,
  },
  mixins: [getFormattedAddress],
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },

  methods: {

    generatePDF() {
      this.loading = true
      const filename = this.getFormattedAddress(this.location.address)
        .concat('_')
        .concat(this.location.id).concat('_')
        .concat(this.$i18n.locale)
        .concat('.pdf')
      const element = this.$refs.printForm
      const opt = {
        margin: 6,
        filename,
      }

      html2pdf().from(element).set(opt).save()
        .then(() => {
          this.loading = false
          this.displayModal = false
        })
    },

    // generatePDF() {
    //   const element = this.$refs.printForm
    //   const doc = new jsPDF({
    //     orientation: 'p',
    //     unit: 'px',
    //     format: 'a4',
    //     hotfixes: ['px_scaling'],
    //   })
    //   html2canvas(element, {
    //     width: doc.internal.pageSize.getWidth(),
    //     height: doc.internal.pageSize.getHeight(),
    //   }).then(canvas => {
    //     const img = canvas.toDataURL('image/png')
    //     doc.text(10, 10, 'This is a test')
    //     doc.addImage(img, 'PNG', 10, 10, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight())
    //     // doc.autoPrint({ variant: 'non-conform' })
    //     doc.save('autoprint.pdf')
    //   })
    // },
  },
  setup() {
    const { localesList } = locales()
    const displayModal = ref(false)
    const loading = ref(false)
    const {
      locationAddRequest,
    } = useLocationUi()
    const { appName, appLogoImage } = $themeConfig.app
    const appUrl = window.location.hostname
    const { getPurposeTitle } = serviceOptions
    const getServicePurposes = [
      'home_master',
      'plumber',
      'electrician',
      'minor_household_repairs',
      'painting_works',
      'installation_of_household_appliances',
      'equipment_repair',
      'furniture_repair',
      'car_repair',
      'tire_fitting',
      'bicycle_repair',
      'interpreter',
      'foreign_language_tutors',
      'transport_services',
      'cleaning_of_apartments_and_houses',
      'car_rental',
      'photography',
      'manicure',
      'hairdressing_services',
      'animal_care',
      'veterinary_services',
      'decoration_of_holidays',
      'clothing_repair_and_tailoring',
      'paediatrics',
      'dentistry',
    ]

    return {
      displayModal,
      loading,
      locationAddRequest,
      appName,
      appLogoImage,
      appUrl,
      getServicePurposes,
      getPurposeTitle,
      localesList,
    }
  },
}
</script>
<style lang="scss" scoped>
.lang{
  margin-right: 0.2em;
}
</style>
