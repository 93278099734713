<template>
  <b-card
    id="openMapModal"
    no-body
  >
    <b-card-header>
      <b-card-title>
        <div class="d-flex">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            {{ $t('Address') }}
          </h4>
        </div>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <div v-show="modalMapShow">
            <network-status @connection-restored="refreshMap" />
            <b-alert
              v-if="alertMessage"
              show
              variant="danger"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <span class="ml-25">{{ alertMessage }}</span>
              </div>
            </b-alert>
            <l-map
              v-if="displayMap"
              ref="map"
              :center="center"
              :options="map.options"
              style="height: 240px; width: 100%"
            >
              <l-tile-layer :url="tileProvider.url" />
              <l-marker
                v-if="location.position.lat && location.position.lng"
                visible
                :draggable="editable"
                :lat-lng.sync="location.position"
                @dragstart="dragging = true"
              />
            </l-map>
            <h5 class="my-1">
              {{ location.formatted_address }}
            </h5>

          </div>
          <b-card-text>
            <b-form>
              <b-row>
                <b-col
                  md="12"
                  lg="6"
                  xl="4"
                >
                  <b-form-group
                    :label="$t('Country')"
                    label-for="country"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Country')"
                      rules="required"
                    >
                      <b-form-input
                        id="country"
                        v-model="location.address.country"
                        v-capitalize-first-letter
                        trim
                        :readonly="!editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                  lg="6"
                  xl="4"
                >
                  <b-form-group
                    :label="$t('State')"
                    label-for="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('State')"
                      rules="required"
                    >
                      <b-form-input
                        id="state"
                        v-model="location.address.state"
                        v-capitalize-first-letter
                        trim
                        :readonly="!editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                  xl="4"
                >

                  <b-form-group
                    :label="$t('Locality')"
                    label-for="locality"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Locality')"
                      rules="required"
                    >
                      <b-form-input
                        id="locality"
                        v-model="location.address.locality"
                        v-capitalize-first-letter
                        trim
                        :readonly="!editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  xl="6"
                  md="12"
                >
                  <b-form-group
                    :label="$t('Street')"
                    label-for="road"
                  >
                    <b-form-input
                      id="road"
                      v-model="location.address.road"
                      trim
                      :readonly="!editable"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  xl="2"
                >
                  <b-form-group
                    :label="$t('House')"
                    label-for="house_number"
                  >
                    <b-form-input
                      id="house_number"
                      v-model="location.address.house_number"
                      :disabled="!location.address.road"
                      trim
                      :readonly="!editable"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  xl="2"
                >
                  <b-form-group
                    :label="$t('Apt.')"
                    label-for="apartment_number"
                  >
                    <b-form-input
                      id="apartment_number"
                      v-model="location.address.apartment_number"
                      :disabled="!location.address.house_number"
                      trim
                      :readonly="!editable"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  xl="2"
                >
                  <b-form-group
                    :label="$t('Postcode')"
                    label-for="postcode"
                  >
                    <b-form-input
                      id="postcode"
                      v-model="location.address.postcode"
                      trim
                      :readonly="!editable"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-form>
          </b-card-text>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardText,
  BAlert,
} from 'bootstrap-vue'

import { ValidationProvider } from 'vee-validate'

import {
  LMap, LTileLayer, LMarker,
} from 'vue2-leaflet'
import {
  required, confirmed,
} from '@validations'
import { nextTick } from '@vue/composition-api'
// import { heightFade } from '@core/directives/animations'
import { capitalizeFirstLetter } from '@/directives/capitalize'
import {
  Icon,
} from 'leaflet'

import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'

import { getFormattedAddress } from '@/mixins/functions'

import networkStatus from '@/layouts/components/NetworkStatus.vue'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    BAlert,
    LMap,
    LTileLayer,
    LMarker,

    ValidationProvider,
    networkStatus,

  },
  directives: {
    'capitalize-first-letter': capitalizeFirstLetter,
  },
  mixins: [getFormattedAddress],
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: 49.444444,
        lng: 32.059722,
      }),
    },
    editable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    displayMap: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    viewport: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      confirmed,
      modalMapShow: false,
      modalPermissionShow: false,
      point: this.data,
      dragging: false,
      loading: false,
      error: null,
      location: {
        address: {
          country: '',
          country_code: '',
          postcode: '',
          locality: '',
          road: '',
          state: '',
          house_number: '',
          apartment_number: '',
        },
        formatted_address: '',
        address_confirmed: false,
        position: {
          lat: null,
          lng: null,
        },
        radius: 0,
        viewport: {
          _southWest: {
            lat: 0,
            lng: 0,
          },
          _northEast: {
            lat: 0,
            lng: 0,
          },
        },
        viewportRadius: 0,
        viewportPosition: {
          lat: null,
          lng: null,
        },
        viewportBounds: {},
      },
      alertMessage: '',
      map: {
        options: {
          preferCanvas: true,
          zoomControl: true,
          attributionControl: false,
          zoomSnap: true,
          zoom: 18,
          minZoom: 6,
          maxZoom: 30,
          scrollWheelZoom: false,
        },
      },
      tileProvider: {
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },

      tooltip: {
        options: {
          permanent: true,
          direction: 'top',
          interactive: true,
          noWrap: true,
          opacity: 0.9,
        },
      },
    }
  },
  computed: {
    center() {
      return [
        this.location.position.lat || this.defaultLocation.lat,
        this.location.position.lng || this.defaultLocation.lng,
      ]
    },

  },

  mounted() {
    this.$watch('data', newValue => {
      if (newValue.id) {
        this.point = newValue
        this.showMapModal()
        this.setPosition(this.point.lat, this.point.lng)
        this.location.address = this.point.address
        this.location.id = this.point.id
        this.location.formatted_address = this.getFormattedAddress(this.location.address)
        this.$emit('update-location', this.location)
      }
    }, { immediate: true })
  },
  methods: {
    refreshMap() {
      return true
    },
    showMapModal() {
      this.modalMapShow = true
      this.modalPermissionShow = false
      nextTick()
      this.rerenderMap()
    },
    rerenderMap() {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },

    transformFetchedLocality(location) {
      let locality = null
      if ('city' in location.address) {
        locality = location.address.city
      }
      if ('town' in location.address) {
        locality = location.address.town
      }
      if ('village' in location.address) {
        locality = location.address.village
      }
      return locality
    },

    setPosition(lat, lng) {
      this.location.position = { lat, lng }
      localStorage.setItem('lat', lat)
      localStorage.setItem('lng', lng)
    },

    setError(error) {
      this.error = error
      this.loading = false
    },
    setLoadingState() {
      this.error = null
      this.loading = true
    },

  },
  setup() {

  },

}
</script>
<style>
.leaflet-top, .leaflet-bottom {
  z-index: auto!important;
}
.leaflet-container{
  z-index: 0;
}
#map-modal .alert,
#map-modal .form-control{
  border-radius: 0;
  margin-bottom: 0!important;
}
#openMapModal .alert:not(#map-modal .alert){
  margin-bottom: 0.5rem!important;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
