<template>
  <div class="text-center">
    <qrcode-vue
      :value="link"
      :size="156"
      level="H"
      render-as="svg"
    /></div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import QrcodeVue from 'qrcode.vue'
import { useLocationUi } from '@/views/apps/location/useLocation'
import { ref } from '@vue/composition-api'

export default {
  components: {
    QrcodeVue,
  },
  directives: {
    Ripple,
  },

  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {

    }
  },

  methods: {

  },
  setup(props) {
    const link = ref('')

    const {
      locationEdit, getLocationRequestLink,
    } = useLocationUi()

    link.value = getLocationRequestLink(props.location)
    return {
      link,
      locationEdit,
    }
  },
}

</script>
<style scoped>

</style>
