<template>
  <b-card
    no-body
  >
    <b-row
      v-if="ability.can('update', location)"
      class="text-right actions match-height"
    >
      <b-col>
        <location-print :location="location" />
      </b-col>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="locationEdit(location)"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-25 d-none d-sm-inline"
          />
          <span>{{ $t('Edit') }}</span>
        </b-button>

      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { showToast } from '@/mixins/notification/toasts'
import { formatDateTime } from '@/filters/dateTime'
import { useLocationUi } from '@/views/apps/location/useLocation'

import LocationPrint from '@/views/apps/location/details/LocationPrint.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    LocationPrint,

  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateTime,
  },
  mixins: [showToast],
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
    proposalAdded: {
      type: Boolean,
      default: false,
    },
  },

  methods: {

  },
  setup() {
    const ability = defineAbilityForCurrentUser()
    const {
      locationEdit,
    } = useLocationUi()
    return {
      ability,
      locationEdit,
    }
  },
}

</script>
<style scoped>

</style>
